import { defineNuxtRouteMiddleware, navigateTo } from "nuxt/app";

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore();
  await authStore[GET_USER]();
  const config = useRuntimeConfig();

  if (!authStore.isLoggedIn) {
    return;
  }

  if (to.path.includes("/onboarding/login") || to.path.includes("/reactivate")) {
    if (authStore.user?.subscription?.status === "cancelled") {
      navigateTo(
        config.public.reactivatePageUrl + window.location.search,
        {
          external: true,
        }
      );
    }

    navigateTo(config.public.accountUrl + window.location.search, {
      external: true,
    });
  }
});
